<template>
  <div id="app" class="flex flex-col scroll-smooth">
    <header class="text-gray-600 body-font sticky top-0 py-4 bg-white shadow-xl z-40 transition-all duration-500 ease-in-out">
      <div class="container mx-auto flex flex-wrap xl:p-5 px-5 pr-7 flex-col md:flex-row items-center">

        <nav class="container mx-auto xl:flex xl:justify-between xl:items-center">
          <div class="flex items-center justify-between">

            <a href="/" class="text-xl font-bold text-gray-800 md:text-2xl hover:text-blue-400">
              <img class="h-16" alt="Ranindra Anandita" src="imgs/ra_signature.png">
            </a>
            <!-- Mobile menu button -->
            <div @click="showMenu = !showMenu" class="flex xl:hidden">
              <button class="text-gray-600 w-8 h-12 relative focus:outline-none bg-white">
                <span class="sr-only">Open main menu</span>
                <div class="block w-8 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span aria-hidden="true" class="block absolute h-0.5 w-8 bg-current transform transition duration-500 ease-in-out" :class="{'rotate-45': showMenu,' -translate-y-1.5': !showMenu } "></span>
                    <span aria-hidden="true" class="block absolute h-0.5 w-8 bg-current transform transition duration-500 ease-in-out" :class="{'hidden': showMenu, ' block': !showMenu} "></span>
                    <span aria-hidden="true" class="block absolute h-0.5 w-8 bg-current transform transition duration-500 ease-in-out" :class="{'-rotate-45': showMenu, ' translate-y-1.5': !showMenu}"></span>
                </div>
              </button>
            </div>

          </div>

          <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
          <ul :class="showMenu ? 'h-max opacity-100' : 'h-0 opacity-0 overflow-hidden'" class="xl:h-max xl:opacity-100 text-gray-600 text-2xl flex flex-col xl:flex xl:flex-row items-center xl:ml-auto pompiere transition-all duration-500 ease-in-out">
            <li class="xl:mr-10 xl:mt-0 mt-4 hover:text-gray-900">
              <a @click="showMenu = false" href="#whoami">Qui suis-je ?</a>
            </li>
            <li class="xl:mr-10 xl:mt-0 mt-4 hover:text-gray-900">
              <a @click="showMenu = false" href="#whycome">Pourquoi consulter ?</a>
            </li>
            <li class="xl:mr-10 xl:mt-0 mt-4 hover:text-gray-900">
              <a @click="showMenu = false" href="#services">Les consultations</a>
            </li>
            <li class="xl:mr-10 xl:mt-0 mt-4 hover:text-gray-900">
              <a @click="showMenu = false" href="#contact">Informations</a>
            </li>
            <li>
              <a class="pompiere text-2xl inline-flex bg-pastel hover:bg-pastel-dark items-center border-0 py-1 px-3 focus:outline-non rounded text-white xl:my-0 my-4" href="https://www.doctolib.fr/psychologue/eysines/ranindra-anandita">Prendre rendez-vous
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </li>
          </ul>
        </nav>

        
      </div>
    </header>

    <ImageSlider v-bind:slides="slides" />
    <div class="container mx-auto" >

      <section id="whoami" class="sm:scroll-mt-32 scroll-mt-16">
        <div class="container px-5 sm:pt-24 pt-12 sm:pb-8 pb-4 mx-auto">
          <div class="flex flex-col text-center w-full sm:mb-20 mb-8">
            <h1 class="title sm:text-6xl text-4xl font-medium title-font text-gray-600">Qui suis-je ?</h1>
          </div>
          <p class="text-font sm:text-lg text-md text-gray-700 text-justify font-thin">
            Psychologue du développement, diplômée de l’université de Bordeaux et praticienne thérapie des schémas. Je suis spécialisée dans les problématiques développementales, les difficultés transitoires, la parentalité et dans l’accompagnement des troubles neurodéveloppementaux.
            Je m’attache à la théorie de l’attachement et l’approche systémique dans laquelle nous considérons l’individu dans sa globalité en s’appuyant sur le contexte et les relations.
            Les diverses relations existantes dans la famille sont un aspect important afin de comprendre le comportement de l’enfant ainsi que le fonctionnement familial. Mon attention est aussi portée sur les étapes de développement par lesquelles les personnes passent et comment cela influe sur leurs comportements, leurs personnalités, leurs fonctions mentales, etc…
          </p>
          <div class="text-center">
            <span class="inline-block h-1 w-10 rounded bg-pastel-dark mt-8 mb-6"></span>
          </div>
        </div>
      </section>

      <section id="whycome" class="sm:scroll-mt-32 scroll-mt-16">
        <div class="container px-5 sm:pt-16 pt-12 sm:pb-8 pb-4 mx-auto">
          <div class="flex flex-col text-center w-full sm:mb-20 mb-8">
            <h1 class="title sm:text-6xl text-4xl font-medium title-font text-gray-600">Pourquoi consulter ?</h1>
          </div>
          <p class="text-font sm:text-lg text-md text-gray-700 text-justify font-thin mb-5">
            La psychologie du développement englobe tous les aspects du développement de la personne dans une vision intégrative. L’individu évolue de façon complexe afin de suivre sa propre ligne de développement au cours du temps. Tout au long de notre vie, nous pouvons tous être amenés à traverser des périodes difficiles accompagnées de changements, de doutes ou de questionnements. Le soutien psychologique s'adresse aux enfants, adolescents et adultes. Il peut concerner des difficultés ponctuelles ou anciennes, personnelles ou professionnelles. Le soutien psychologique peut également constituer le premier pas vers une psychothérapie individuelle.
          </p>
          <p class="text-font sm:text-lg text-md text-gray-700 text-justify font-thin mb-5">
            Un accompagnement psychologique chez l’enfant et l’adolescent se focalise sur leur développement. Le chemin n’est pas toujours simple et les obstacles sont parfois cachés. Ils sont souvent "forcés" à s’adapter à leur environnement, à atteindre les attentes de leur entourage, à être comme les autres enfants ou adolescents de leurs âges. Ce n’est pas facile de grandir, surtout quand ils ne se sentent pas entendus ou bien parce qu’ils n’arrivent simplement pas exprimer leurs émotions pour diverses raisons. Les signes de souffrance sont variés, des plus anodins aux plus inquiétants. Cela peut se manifester au travers du comportement (trouble du comportement et/ou comportement régressif).
          </p>
          <p class="text-font sm:text-lg text-md text-gray-700 text-justify font-thin mb-5">
            Je vous propose un espace d'échange sécurisé et bienveillant dans lequel vous pourrez explorer vos pensées, vos émotions et vos comportements afin d'améliorer le bienêtre ou la qualité de votre vie.
          </p>
          <div class="text-center">
            <span class="inline-block h-1 w-10 rounded bg-pastel-dark mt-8 mb-6"></span>
          </div>
        </div>
      </section>

      <section id="services" class="text-gray-600 body-font sm:scroll-mt-32 scroll-mt-16">
        <div class="container px-5 py-16 pt-12 mx-auto">
          <div class="flex flex-col text-center w-full sm:mb-12 mb-8">
            <h1 class="title sm:text-6xl text-4xl font-medium title-font text-gray-600">Les consultations</h1>
          </div>
          <div class="container mx-auto flex px-5 sm:py-16 py-8 md:flex-row flex-col items-center" v-for="(prestation, index) in prestations" :key="index">
            <div v-if="index % 2 === 0 || isMobile()" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img class="sm:float-right rounded" v-bind:alt="prestation.title" v-bind:src="prestation.image" v-bind:class="prestation.size">
            </div>
            <div v-if="index % 2 === 0 || isMobile()" class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 class="title-font sm:text-4xl text-3xl mb-4 pompiere text-pastel-dark">{{prestation.title}}</h1>
              <p class="mb-8 text-font sm:text-lg text-md font-thin text-justify">{{prestation.desc}}</p>
            </div>

            <div v-if="index % 2 != 0 && !isMobile()" class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 class="title-font sm:text-4xl text-3xl mb-4 pompiere text-pastel-dark">{{prestation.title}}</h1>
              <p class="mb-8 text-font sm:text-lg text-md font-thin text-justify">{{prestation.desc}}</p>
            </div>
            <div v-if="index % 2 != 0 && !isMobile()" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
              <img class="sm:float-left rounded" v-bind:alt="prestation.title" v-bind:src="prestation.image" v-bind:class="prestation.size">
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="bg-pastel-light text-font text-gray-600 mt-12">
      <div class="container mx-auto sm:pl-64 p-8 pt-12">
        <div class="flex flex-wrap">
          <div id="contact" class="sm:w-1/2 w-full">
              <h3 class="sm:text-4xl text-3xl pompiere">Ranindra Anandita</h3>
            <ul class="my-6">
              <li>
                <i class="fas fa-earth-americas mr-2"></i>Français, Anglais, Indonésien
              </li>
              <li>
                <i class="fas fa-location-dot mr-2"></i>22 Avenue du Taillan, 33320 Eysines
              </li>
              <li>
                <i class="fas fa-mobile-alt mr-2"></i>
                <a href="tel:0766058862">07 66 05 88 62</a>
              </li>
              <li>
                <i class="fas fa-square-envelope mr-2"></i>
                <a href="mailto:contact@anandita-psychologue.com">contact@anandita-psychologue.com</a>
              </li>
            </ul>
          </div>

          <div class="sm:w-1/2 w-full">
            <h2 class="sm:text-4xl text-3xl pompiere">Tarifs</h2>
            <ul class="my-6">
              <li class="flex flex-wrap">
                <div class="sm:w-1/2 w-3/4 bg-pastel-light">
                  <p class="dots-filled">
                    <span class="dots-text">Consultation</span>
                  </p>
                </div>
                <div class="sm:w-1/2 w-1/4 pl-1">
                  60 €
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container mx-auto p-8 pt-0">
        <div class="italic font-thin text-sm text-center">
          Les consultations ne peuvent pas être remboursées car les psychologues ne sont pas conventionnés par la Sécurité Sociale.
          Cependant, certaines mutuelles peuvent proposer un remboursement des consultations.
          Veuillez vous renseigner auprès de votre mutuelle afin que je puisse vous délivrer les justificatifs nécessaires.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ImageSlider from './components/ImageSlider.vue'

export default {
  name: 'App',
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
  },
  components: {
    ImageSlider
  },
  data() {
    return {
      showMenu : false,
      slides: [
        {
          mainText : "Ranindra Anandita",
          subText : "Psychologue du développement",
          type : "title",
          image : 'imgs/slides/sunflower.jpg',
        },
        {
          mainText : "La famille. Comme les branches d'un arbre, nous grandissons tous dans de différentes directions, mais nos racines ne font qu'un.",
          subText : "inconnu",
          type : "quote",
          image : 'imgs/slides/balloons.jpg',
        },
        {
          mainText : "As your kids grow they may forget what you said, but won't forget how you made them feel.",
          subText : "Kevin Heatht",
          type : "quote",
          image : 'imgs/slides/crayons.jpeg',
        },
        {
          mainText : "Il faut du courage pour grandir et pour devenir la personne que nous sommes vraiment.",
          subText : "E. E. Cummings",
          type : "quote",
          image : 'imgs/slides/origami.jpg',
        },
        {
          mainText : "There are no perfect parents, and there are no perfect children, but there are plenty of perfect moments along the way.",
          subText : "Dave Willis",
          type : "quote",
          image : 'imgs/slides/flowers.jpg',
        }
      ],
      patients: [
        {
          type : "Enfant",
          img : "imgs/kid.svg",
        },
        {
          type : "Adolescent",
          img : "imgs/teen.svg",
        },
        {
          type : "Famille",
          img : "imgs/family.svg",
        },
      ],
      prestations: [
        { 
          title : "Soutien psychologique - Suivi thérapeutique",
          desc : "La mise en place d’un soutien psychologique permet de travailler ensemble sur certaines problématiques, telles que le stress, la dépression, l’anxiété et le traumatisme. L’objectif est de vous aider à comprendre et à surmonter vos difficultés en analysant votre situation, vos émotions et vos comportements. Je vous accompagne ainsi à apprendre à écouter vos émotions et comprendre leur signification, vous connecter à vous-même, donner du sens à vos actions et à vos choix ainsi que prendre du recul face à vos difficultés. De plus, un travail plus en profondeur pourrait être proposé dans un cadre de suivi thérapeutique. Le nombre de séances est adapté en fonction de l'avancée du travail.",
          image : "imgs/consult/soutienpsy.png",
          size : "sm:h-80"

        },
        { 
          title : "Remédiation cognitive",
          desc : "Connaitre son fonctionnement, ses point fortes et point faibles, afin de pouvoir s’en servir efficacement. L’objectif est de l’aider votre enfant à favoriser l’acquisition de nouvelles stratégies, à améliorer leurs capacités et à développer des stratégies pour compenser ses difficultés (développement des fonctions exécutives, de l’attention, de la mémoire de travail, de la métacognition…).",
          image : "imgs/consult/remcogn.png",
          size : "sm:h-80"
        },
        { 
          title : "Remédiation sur le plan social et affectif",
          desc : "Apprendre à mieux se connaitre et à surmonter des difficultés sur le plan relationnel. Le travail autour des émotions pour pouvoir connaitre et à mieux réguler ses émotions.",
          image : "imgs/consult/remsocial.png",
          size : "sm:h-96"
        },
        { 
          title : "Soutien à la parentalité",
          desc : "Le soutient à la parentalité sous la forme de la guidance parentale. Il s'agit de soutenir les parents dans la compréhension du niveau de développement de leurs enfants et les accompagner à traverser des problématiques repérés qui se sont tissés au sein de la famille.",
          image : "imgs/consult/accompfam.png",
          size : "sm:h-80"
        },
        { 
          title : "Bilans psychologiques",
          desc : "Le bilan psychologique permettra d'orienter l'accompagnement et le suivi. Le bilan nous aide à identifier les ressources et les fragilités de la personne afin de se représenter où l'individu en est de son développement. Cela permet également de comprendre les modes affectifs et relationnels de l'enfant dans la façon dont il se perçoit lui-même, la dynamique de ses émotions. Cela permet par la suite d'orienter l'accompagnement et le suivi.",
          image : "imgs/consult/bilan.png",
          size : "sm:h-96"
        },
      ]
    }
  }
}
</script>

<style>
</style>
