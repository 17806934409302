<template>
  <div class="sm:h-[500px] h-72 relative slider-content flex-shrink-0 bg-black">
    <div
      v-for="(slide, index) in slides"
      v-bind:key="slide"
      v-bind:data-index="index"
      class="absolute sm:h-[500px] h-72 w-full transition-opacity duration-1000 ease-out opacity-100"
    >
      <img class="w-full h-full object-cover" v-bind:src="slide.image" />
      <div v-if="slide.type == 'title'" class="absolute w-full sm:py-[175px] py-8 bottom-0 inset-x-0 text-white title text-center">
        <h1 class="sm:text-8xl text-6xl font-medium title-font drop-shadow-md">{{slide.mainText}}</h1>
        <h2 class="sm:text-5xl text-2xl tracking-widest font-medium title-font mt-10 drop-shadow-md">{{slide.subText}}</h2>
      </div>
      <div v-if="slide.type == 'quote'" class="absolute w-full sm:py-[175px] py-16 bottom-0 inset-x-0 text-white title text-center">
        <h1 class="sm:text-5xl text-2xl font-medium title-font sm:px-32 px-4 drop-shadow-md">"{{slide.mainText}}"</h1>
        <h2 class="sm:text-3xl text-xl tracking-widest font-medium title-font mt-10 drop-shadow-md">{{slide.subText}}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSlider',
  data() {
      return {
          currentSlideIndex: 0,
      }
  },
  props: {
      slides: Array
  },
  methods: {
      getNextSlideIndex () {
        if(this.currentSlideIndex + 1 < this.slides.length) {
          return this.currentSlideIndex += 1;
        }
        this.currentSlideIndex = 0;
        return 0;
      },
      nextSlide () {
        const currentSlide = document.querySelector(`[data-index="${this.currentSlideIndex}"`);
        const nextSlideIndex = this.getNextSlideIndex();
        const nextSlide = document.querySelector(`[data-index="${nextSlideIndex}"`);
        currentSlide.classList.add('opacity-0');
        nextSlide.classList.remove('opacity-0');
      },
      play () {
        const app = this;
        this.timer = setInterval(function() {
            app.nextSlide();
        }, 6000);
      }
  },
  mounted() {
      this.slides.forEach((slide,index) => {
          if (index !== this.currentSlideIndex) {
            const element = document.querySelector(`[data-index="${index}"`);
            element.classList.add('opacity-0');
          }
      })
  },
  created() {
      this.play();
  }
}
</script>